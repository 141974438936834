/*
 * MOBILEDOC-KIT DEFAULT EDITOR STYLES.
 * Copied from node_modules/mobiledoc-kit/src/css/mobiledoc-kit.css
 * with heavy modification.
 */

.mobiledoc-core {
  /**
  * Editor
  */

  &[class*='__mobiledoc-editor'] {
    position: relative;
  }

  &[class*='__mobiledoc-editor']:focus {
    outline: none;
  }

  &[class*='__mobiledoc-editor'] > * {
    position: relative;
  }

  &[class*='__mobiledoc-editor __has-no-content']:after {
    content: attr(data-placeholder);
    cursor: text;
    position: absolute;
    color: var(--chakra-colors-slate-light);
    cursor: text;
    top: 20px;
    font-size: 1rem;
    width: calc(100% - 2rem);
  }

  &[class*='__mobiledoc-editor'] a {
    white-space: wrap;
  }

  &[class*='__mobiledoc-editor'] h1,
  &[class*='__mobiledoc-editor'] h2,
  &[class*='__mobiledoc-editor'] h3,
  &[class*='__mobiledoc-editor'] h4,
  &[class*='__mobiledoc-editor'] h5,
  &[class*='__mobiledoc-editor'] h6 {
    font-weight: 600;
  }

  &[class*='__mobiledoc-editor'] img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  &[class*='__mobiledoc-editor'] div,
  &[class*='__mobiledoc-editor'] iframe {
    max-width: 100%;
  }

  /**
  * Cards
  */

  [class*='__mobiledoc-card'] {
    display: inline-block;
    width: 100%;
  }

  /**
  * Tooltips
  */

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  [class*='__mobiledoc-tooltip'] {
    font-size: 0.875rem;
    white-space: nowrap;
    position: absolute;
    background-color: rgba(43, 43, 43, 0.9);
    border-radius: 3px;
    line-height: 1em;
    padding: 0.7em 0.9em;
    color: #fff;
    animation: fade-in 0.2s;
  }

  [class*='__mobiledoc-tooltip']:before {
    content: '';
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 0.4em solid transparent;
    border-right: 0.4em solid transparent;
    border-bottom: 0.4em solid rgba(43, 43, 43, 0.9);
    top: -0.4em;
    margin-left: -0.4em;
  }

  /* help keeps mouseover state when moving from link to tooltip */
  [class*='__mobiledoc-tooltip']:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -0.4em;
    height: 0.4em;
  }

  [class*='__mobiledoc-tooltip'] a {
    color: #fff;
    text-decoration: none;
  }

  [class*='__mobiledoc-tooltip'] a:hover {
    text-decoration: underline;
  }
}
