.link-dialog {
  width: 300px;
  padding: 1rem 1.5rem 2rem;
  z-index: var(--chakra-zIndices-popover);

  @media (min-width: 768px) {
    width: 400px;
  }

  fieldset {
    padding: 0.5rem 0;
    &:last-of-type {
      padding-top: 1rem;
    }
  }
}
