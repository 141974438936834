.rich-text-editor {
  composes: mobiledoc-core from './mobiledocCore.module.scss';
  padding: 1.25rem 1rem;
  min-height: 18rem;
  box-shadow: none;

  &:focus {
    outline: none;
  }
}
