@import '~rc-tooltip/assets/bootstrap_white.css';

.rc-tooltip {
  opacity: 1 !important;
  animation: fadeIn 0.4s;
  background: none;
  &.rc-tooltip-placement-right {
    .rc-tooltip-arrow {
      border-right-color: var(--chakra-colors-white);
    }
  }
  &.rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      border-left-color: var(--chakra-colors-white);
    }
  }
  &.rc-tooltip-placement-top {
    .rc-tooltip-arrow {
      border-top-color: var(--chakra-colors-white);
    }
  }
  &.rc-tooltip-placement-bottom {
    .rc-tooltip-arrow {
      border-bottom-color: var(--chakra-colors-white);
    }
  }
  .rc-tooltip-inner {
    border-radius: var(--chakra-radii-secondary);
    animation: fadeInShadow 0.6s;
    max-width: 500px;
    font-size: var(--chakra-fontSizes-sm);
    background: var(--chakra-colors-white);
    padding: 0;
    border: none;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInShadow {
  from {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  }
  to {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
  }
}
