.link-tooltip {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.url-wrapper {
  padding: 1rem 1.25rem;
  color: var(--chakra-colors-cerulean);
  &:hover {
    color: var(--chakra-colors-slate-dark);
  }
}

.url-display {
  overflow: hidden;
  line-height: var(--chakra-lineHeights-xs);
  max-height: calc(var(--chakra-lineHeights-xs) * 3);
  display: block;
}

.no-url {
  font-style: italic;
  color: var(--chakra-colors-titanium);
}

.button {
  padding: 1rem 1.25rem;
  font-size: inherit;
  border-left: 1px solid var(--chakra-colors-silver-lighter);
  color: var(--chakra-colors-cerulean);
  background: transparent;
  height: 100%;
  &:hover {
    color: var(--chakra-colors-slate-dark);
  }
}
